import { createMuiTheme } from "@material-ui/core";
// According to recommendations in Refactoring UI.
const spaceToPixels = (n) => {
  switch (n) {
    case 1:
      return 4;
    case 2:
      return 8;
    case 3:
      return 12;
    case 4:
      return 16;
    case 5:
      return 24;
    case 6:
      return 32;
    case 7:
      return 48;
    case 8:
      return 64;
    case 9:
      return 96;
    case 10:
      return 128;
    case 11:
      return 192;
    case 12:
      return 256;
    case 13:
      return 384;
    case 14:
      return 512;
    case 15:
      return 640;
    case 16:
      return 768;
    default:
      return 0;
  }
};

const theme = createMuiTheme(
  {
    navbar: {
      height: {
        large: 175,
        small: 70,
      }
    },
    custom: {
      standardBorder: '3px solid #090213',
      largeBorder: '4px solid #090213',
    },
    palette: {
      darkPurple: '#090213',
      primaryPink: {
        light1: '#FAF2FC',
        main: '#EFD9F7'
      },
      primaryYellow: {
        light1: '#FDEFBE',
        main: '#FCE592',
        dark1: '#FAD349',
        dark2: '#685203',
      },
      font: {
        dark: '#010713',
      },
      primary: { main: 'hsl(167, 23%, 55%)' },
      secondary: { main: 'hsl(13, 90%, 69%)' },
    },
    typography: {
      color: {
        darkTheme: '#FFF',
        lightTheme: '#000',
      },
      fontFamily: ['Rubik, helvetica, sans-serif'].join(','),
      fontSize: 12,
      htmlFontSize: 10,
      subtitle1: {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.75,
        letterSpacing: '0.00938em',
      },
      subtitle2: {
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: 1.57,
        letterSpacing: '0.00714em',
      },
      body2: {
        fontFamily: ['Cabin, helvetica, sans-serif'].join(','),
        fontWeight: 500,
        fontSize: 18,
        lineHeight: 1.2,
        letterSpacing: '0.01071em',
      },
      button: {
        fontFamily: ['Cabin, helvetica, sans-serif'].join(','),
        fontWeight: 'bold',
        fontSize: 16,
        lineHeight: 1.2,
        letterSpacing: 1.25,
        textTransform: 'uppercase',
      },
      caption: {
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 1.66,
        letterSpacing: '0.03333em',
      },
    },
    spacing: (...args) => {
      switch (args.length) {
        case 1:
          return spaceToPixels(args[0]);
        case 2:
          return `${spaceToPixels(args[0])} ${spaceToPixels(args[1])}`;
        case 4:
          return `${spaceToPixels(args[0])} ${spaceToPixels(args[1])} ${spaceToPixels(
            args[2],
          )} ${spaceToPixels(args[3])}`;
        default:
          return 0;
      }
    },
  }

);

const rubikFontFamily = ['Rubik, helvetica, sans-serif'].join(',');
const cabinFontFamily = ['Cabin, helvetica, sans-serif'].join(',');

theme.typography.body1 = {
    fontFamily: cabinFontFamily,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 1.2,
    letterSpacing: 0,
    [theme.breakpoints.up('md')]: {
      fontFamily: cabinFontFamily,
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: 1.2,
      letterSpacing: '0.00938em',
    },
}

theme.typography.h1 = {
    fontFamily: rubikFontFamily,
    fontWeight: 700,
    fontSize: 48,
    lineHeight: 1.167,
    letterSpacing: '-1px',
    [theme.breakpoints.up('md')]: {
      fontWeight: 700,
      fontSize: '96px',
      lineHeight: 1.167,
      letterSpacing: '-1px',
    },
}

theme.typography.h2 = {
    fontFamily: rubikFontFamily,
    fontWeight: 'bold',
    fontSize: 32,
    lineHeight: '120%',
    letterSpacing: '-0.5px',
    [theme.breakpoints.up('md')]: {
      fontWeight: 'bold',
      fontSize: 60,
      lineHeight: '120%',
      letterSpacing: '-0.5px',
    },
}

theme.typography.h3 = {
    fontFamily: rubikFontFamily,  
    fontWeight: 'bold',
    fontSize: 28,
    lineHeight: 1.2,
    letterSpacing: 0,
    [theme.breakpoints.up('md')]: {
      fontWeight: 'bold',
      fontSize: 48,
      lineHeight: 1.2,
      letterSpacing: 0,
    },
}

theme.typography.h4 = {
    fontFamily: rubikFontFamily,
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 1.2,
    letterSpacing: 0,
    [theme.breakpoints.up('md')]: {
      fontWeight: 500,
      fontSize: 34,
      lineHeight: 1.2,
      letterSpacing: 0,
    },
}

theme.typography.h5 = {
    fontFamily: rubikFontFamily,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.334,
    letterSpacing: '0em',
    [theme.breakpoints.up('md')]: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
}

theme.typography.h6 = {
    fontFamily: rubikFontFamily,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.6,
    letterSpacing: '0px',
    [theme.breakpoints.up('md')]: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 1.6,
      letterSpacing: '0px',
    },
}





export default theme;