import React from "react"
import { AnimateSharedLayout } from "framer-motion"
import { PreviewStoreProvider } from 'gatsby-source-prismic'
import { ColorThemeProvider } from "./src/contexts/ColorThemeContext"

export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>
    <ColorThemeProvider>{element}</ColorThemeProvider>
  </PreviewStoreProvider>
)

export const wrapPageElement = ({ element }) => (
  <AnimateSharedLayout>
    {element}
  </AnimateSharedLayout>
)

//Force service worker to reload when there is a new version.
export const onServiceWorkerUpdateReady = () => window.location.reload();
