import React from 'react';
import { createContext } from 'react';


export const ColorThemeContext = createContext();

export function ColorThemeProvider({ children }) {
  
  // const [colorMode, setColorMode] = useState('LIGHT_MODE')
  
  // const darkTheme = {
  //   font: '#000',
    
  // }
  
  const lightTheme = {
    font: '#FFF',
  }



  return <ColorThemeContext.Provider value={lightTheme}>{children}</ColorThemeContext.Provider>;
}
